<template>
  <div>
    <router-view class="only-bottom-frame"></router-view>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "CommonView",
  components: { Footer}
}
</script>

<style scoped>
.only-bottom-frame{
  min-width: 1140px;
}
</style>
